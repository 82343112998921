<template>
	<screen class="search-nonprofit-view" size="large">
		<guest-template
			slot="content"
			navHeaderTitle="Find a Nonprofit"
			:showNavHeader="true"
			:showNavFooter="false"
			routerPath="select-cause"
		>
			<v-container fluid>
				<v-row
					justify="center"
					align="start"
					class="hero mb-10 pb-6"
					:style="{
						backgroundImage: `url(${heroImage})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat'
					}"
				>
					<v-col cols="8">
						<h1 class="text-center mt-5" style="font-size: 24px">
							Support Your Passion
						</h1>
						<h2
							class="mt-5 mb-5 text-center"
							style="font-size: 18px; font-weight: 400 !important"
						>
							Explore over 1 million nonprofits you can donate to
							through RoundUp App.
						</h2>
						<Search
							defaultSearchBarMinWidth="720px"
							class="mb-5"
							:searchOnly="true"
							defaultWidth="480px"
						/>
					</v-col>
				</v-row>
				<SearchCategories
					listDirection="vertical"
					defaultShow="true"
					@click="onShowAll"
					breakpoint="lg"
				/>
			</v-container>
		</guest-template>
	</screen>
</template>

<script>
import { mapActions } from 'vuex'
import guestTemplate from '../../templates/guestTemplate.vue'
import Screen from '../../ui/Screen.vue'
export default {
	name: 'SearchNonprofit',
	components: { guestTemplate, Screen },
	props: {
		heroImage: {
			type: String,
			default:
				'https://roundup-website.s3.amazonaws.com/find-nonprofit-hero.jpg'
		}
	},
	data: () => ({}),
	methods: {
		...mapActions(['updateQuery', 'updateFilters']),

		onShowAll(cat) {
			if (cat) {
				this.onShowResults({ category: cat.title })
			} else {
				this.$router.push({ name: 'search.categories' })
			}
		},

		onShowResults(params) {
			this.updateFilters(params)
			this.$router.push({
				name: 'search.results',
				params
			})
		}
	},
	mounted() {
		this.updateFilters({
			category: null,
			subcategory: null,
			location: {
				city: null,
				state: null
			},
			zip: null,
			partner: null,
			query: null
		})
	}
}
</script>

<style lang="scss">
@import '../../../styles/typography-screen';

.search-nonprofit-view {
	padding: 0px !important;
	> .screen-sub,
	> .screen-body {
		display: none;
	}
	min-height: 95vh !important;
	.container {
		max-width: unset !important;
	}
	h1 {
		font-size: 36px !important;
		font-family: 'Bitter', serif;
		color: #333 !important;
		margin: 0;
		line-height: normal !important;
		font-weight: 400 !important;
	}
	.hero > .col {
		display: flex;
		flex-direction: column;
		max-width: 100%;
		justify-content: center;
		align-items: center;
		flex: 0 0 100%;
	}
	h2 {
		font-size: 20px;
		font-weight: 300;
		color: #333 !important;
		line-height: normal !important;
	}
}
</style>
